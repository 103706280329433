import {AllShortcutsEnabled} from '@github-ui/code-view-shared/components/AllShortcutsEnabled'
import {useReposAppPayload} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import {useShortcut} from '@github-ui/code-view-shared/hooks/shortcuts'
import {useUrlCreator} from '@github-ui/code-view-shared/hooks/use-url-creator'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {ssrSafeDocument, ssrSafeWindow} from '@github-ui/ssr-utils'
import {useLayoutEffect} from '@github-ui/use-layout-effect'
import {useSearchParams} from '@github-ui/use-navigate'
import {KebabHorizontalIcon} from '@primer/octicons-react'
import {ActionList, ActionMenu, IconButton} from '@primer/react'
import React, {useImperativeHandle, useState} from 'react'
import {createPortal} from 'react-dom'

// eslint-disable-next-line no-restricted-imports
import {copyText} from '../../../../github/command-palette/copy'
import {useDeferredMetadata} from '../../../contexts/DeferredMetadataContext'
import {
  calculatePosition,
  calculateTextContentFromElement,
  positionerID,
  useHighlightedLineMenuOptions,
} from '../../../hooks/use-highlighted-line-menu-options'
import type {SetStickyLinesType} from '../../../hooks/use-sticky-lines'
import {forceAnnouncementToScreenReaders} from '../../../utilities/lines'
import type {CodeLineData} from './Code/hooks/use-code-lines'

const portalRootID = 'highlighted-line-menu-container'
export const firstOptionId = 'highlighted-line-menu-first-option'

export function HighlightedLineMenuContainer({children}: React.PropsWithChildren) {
  return (
    <div id={positionerID} className="position-relative">
      {children}
      <div id={portalRootID} />
    </div>
  )
}

interface HighlightedLineMenuProps {
  codeLineClassName?: string
  offset?: {x: number; y: number}
  lineData?: CodeLineData | null
  onLineStickOrUnstick?: SetStickyLinesType
  onMenuClose?: (value: boolean, otherValue: boolean) => void
  onCollapseToggle?: () => void
  openOnLoad?: boolean
  cursorRef?: React.RefObject<HTMLDivElement>
  rowBeginId: string
  rowBeginNumber: number
  rowEndId: string
  rowEndNumber: number
}

export interface HighlightedLineMenuHandle {
  setAnchor(anchor: HTMLElement | null): void
}

const HighlightedLineMenu = React.memo(React.forwardRef(HighlightedLineMenuWithRef))

export default HighlightedLineMenu

function HighlightedLineMenuWithRef(
  {
    codeLineClassName,
    offset,
    lineData,
    onLineStickOrUnstick,
    onMenuClose,
    onCollapseToggle,
    openOnLoad = false,
    cursorRef,
    rowBeginId,
    rowBeginNumber,
    rowEndId,
    rowEndNumber,
  }: HighlightedLineMenuProps,
  ref: React.ForwardedRef<HighlightedLineMenuHandle>,
) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  useImperativeHandle(ref, () => ({setAnchor}))
  const actionMenuRef = React.useRef<HTMLLIElement>(null)
  const {githubDevUrl} = useReposAppPayload()

  const showModelsPromptLink = useFeatureFlag('github_models_prompt_link')

  //make the button itself invisible when the menu is being opened up next to the cursor
  //also hide the button it is being repositioned so that it doesn't flash in the corner
  const [visibility, setVisibility] = useState(openOnLoad ? 'hidden' : 'visible')

  const [position, setPosition] = useState<React.CSSProperties | undefined>(undefined)

  // We need to wait for the virtualization render in order to know where this position should be
  // due to possible word wrapping
  useLayoutEffect(() => {
    const changePosition = () => {
      setVisibility('hidden')
      requestAnimationFrame(() => {
        setPosition(calculatePosition(anchor, offset))
        setVisibility('visible')
      })
    }
    changePosition()

    // eslint-disable-next-line github/prefer-observers
    ssrSafeWindow?.addEventListener('resize', changePosition)

    return () => {
      ssrSafeWindow?.removeEventListener('resize', changePosition)
    }
  }, [anchor, offset])

  useLayoutEffect(() => {
    if (openOnLoad) {
      if (cursorRef) setAnchor(cursorRef.current)
      const timeout = window.setTimeout(() => {
        setOpen(true)
        //50ms for the timeout because that gives the menu enough time to render and reposition itself so the
        //menu doesn't open up in the corner
      }, 50)

      return () => {
        window.clearTimeout(timeout)
      }
    }
    //we only want this to run when the component is initialized
    // eslint-disable-next-line react-compiler/react-compiler
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const useEndLineNumber = rowBeginId !== rowEndId

  const {newDiscussionPath, newIssuePath} = useDeferredMetadata()
  const {refSelectorShortcut} = useShortcut()

  const [open, setOpen] = React.useState(false)
  const {createPermalink, getUrl} = useUrlCreator()
  const {setShouldBeOpen, expandOrCollapseSection, openUpRefSelector} = useHighlightedLineMenuOptions({
    lineData,
    onLineStickOrUnstick,
    onMenuClose,
    onCollapseToggle,
    setOpen,
  })

  const [urlParams] = useSearchParams()
  const isPlain = urlParams.get('plain') === '1'

  const permalinkUrl = createPermalink({absolute: true, params: isPlain ? 'plain=1' : undefined})
  const encodedPermalinkUrl = encodeURIComponent(permalinkUrl)

  const linkHash = `L${rowBeginNumber}${useEndLineNumber ? `-L${rowEndNumber}` : ''}`

  const openAsPromptInModels = () => {
    let begin = rowBeginNumber
    let end = rowEndNumber

    // if only one line is selected, pull ten lines of context on each side
    if (rowBeginNumber === rowEndNumber) {
      begin = rowBeginNumber - 10
      end = rowEndNumber + 10
    }

    let text = ''
    for (let i = begin; i <= end; i++) {
      text += `${calculateTextContentFromElement(ssrSafeDocument?.getElementById(`LC${i}`) ?? null)}${
        i !== end ? '\n' : ''
      }`
    }

    const encodedText = encodeURIComponent(text)
    ssrSafeWindow?.open(`/marketplace/models/azure-openai/gpt-4o/playground?mp=${encodedText}`, '_blank')
  }

  const element =
    position === undefined ? null : (
      <ActionMenu open={open} onOpenChange={setShouldBeOpen}>
        <ActionMenu.Anchor>
          {/* eslint-disable-next-line primer-react/a11y-remove-disable-tooltip */}
          <IconButton
            unsafeDisableTooltip
            className={codeLineClassName}
            size="small"
            icon={KebabHorizontalIcon}
            aria-label={`Code line ${rowBeginNumber} options`}
            data-testid="highlighted-line-menu-button"
            sx={{
              alignSelf: 'center',
              zIndex: 3,
              position: 'absolute',
              lineHeight: '16px',
              height: '24px',
              width: '24px',
              visibility,
              ...position,
            }}
          />
        </ActionMenu.Anchor>
        <ActionMenu.Overlay width="small">
          <ActionList data-testid="highlighted-line-menu">
            {rowBeginNumber === rowEndNumber && (
              <ActionList.Item
                onClick={() => {
                  forceAnnouncementToScreenReaders(`Copied line ${rowBeginNumber}.`)
                  const text = calculateTextContentFromElement(
                    ssrSafeDocument?.getElementById(`LC${rowBeginNumber}`) ?? null,
                  )
                  if (text) copyText(text)
                  setShouldBeOpen(false)
                }}
                onSelect={() => {
                  forceAnnouncementToScreenReaders(`Copied line ${rowBeginNumber}.`)
                  const text = calculateTextContentFromElement(
                    ssrSafeDocument?.getElementById(`LC${rowBeginNumber}`) ?? null,
                  )
                  if (text) copyText(text)
                  setShouldBeOpen(false)
                }}
                ref={actionMenuRef}
                className={firstOptionId}
              >
                Copy line
              </ActionList.Item>
            )}
            {rowBeginNumber !== rowEndNumber && (
              <ActionList.Item
                onClick={() => {
                  forceAnnouncementToScreenReaders(`Copied lines ${rowBeginNumber}-${rowEndNumber}.`)
                  let text = ''
                  for (let i = rowBeginNumber; i <= rowEndNumber; i++) {
                    text += `${calculateTextContentFromElement(ssrSafeDocument?.getElementById(`LC${i}`) ?? null)}${
                      i !== rowEndNumber ? '\n' : ''
                    }`
                  }
                  if (text) copyText(text)
                  setShouldBeOpen(false)
                }}
                onSelect={() => {
                  forceAnnouncementToScreenReaders(`Copied lines ${rowBeginNumber}-${rowEndNumber}.`)
                  let text = ''
                  for (let i = rowBeginNumber; i <= rowEndNumber; i++) {
                    text += `${calculateTextContentFromElement(ssrSafeDocument?.getElementById(`LC${i}`) ?? null)}${
                      i !== rowEndNumber ? '\n' : ''
                    }`
                  }
                  if (text) copyText(text)
                  setShouldBeOpen(false)
                }}
                className={firstOptionId}
              >
                Copy lines
              </ActionList.Item>
            )}
            {permalinkUrl && (
              <ActionList.Item
                onClick={() => {
                  forceAnnouncementToScreenReaders(`Copied permalink.`)
                  copyText(permalinkUrl)
                  setShouldBeOpen(false)
                }}
                onSelect={() => {
                  forceAnnouncementToScreenReaders(`Copied permalink.`)
                  copyText(permalinkUrl)
                  setShouldBeOpen(false)
                }}
              >
                Copy permalink
              </ActionList.Item>
            )}
            <ActionList.LinkItem href={getUrl({action: 'blame', hash: linkHash})}>View git blame</ActionList.LinkItem>
            {newIssuePath && permalinkUrl && (
              <ActionList.LinkItem href={`${newIssuePath}?permalink=${encodedPermalinkUrl}`}>
                Reference in new issue
              </ActionList.LinkItem>
            )}
            {newDiscussionPath && permalinkUrl && (
              <ActionList.LinkItem href={`${newDiscussionPath}?permalink=${encodedPermalinkUrl}`}>
                Reference in new discussion
              </ActionList.LinkItem>
            )}
            {githubDevUrl && (
              <ActionList.LinkItem href={githubDevUrl + ssrSafeWindow?.location.pathname.substring(1)}>
                View file in GitHub.dev
              </ActionList.LinkItem>
            )}
            {rowBeginNumber === rowEndNumber && lineData && (
              <ActionList.Item onClick={expandOrCollapseSection} onSelect={expandOrCollapseSection}>
                {lineData.ownedSection && lineData.ownedSection.collapsed ? 'Expand' : 'Collapse'} current section
              </ActionList.Item>
            )}
            <ActionList.Item onClick={openUpRefSelector} onSelect={openUpRefSelector}>
              View file in different branch/tag
              <ActionList.TrailingVisual>
                <AllShortcutsEnabled>{refSelectorShortcut.text}</AllShortcutsEnabled>
              </ActionList.TrailingVisual>
            </ActionList.Item>
            {showModelsPromptLink && (
              <ActionList.Item onClick={openAsPromptInModels} onSelect={openAsPromptInModels}>
                Open as prompt in GitHub Models
              </ActionList.Item>
            )}
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    )

  const portalRoot = ssrSafeDocument?.getElementById(portalRootID)

  return portalRoot ? createPortal(element, portalRoot) : null
}

try{ HighlightedLineMenuContainer.displayName ||= 'HighlightedLineMenuContainer' } catch {}
try{ HighlightedLineMenu.displayName ||= 'HighlightedLineMenu' } catch {}
try{ HighlightedLineMenuWithRef.displayName ||= 'HighlightedLineMenuWithRef' } catch {}